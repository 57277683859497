<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">

            <div class="modal-content p-4" v-if="step === 1">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Kennwort ändern</span>
                    <div class="container px-0 mt-4">
                        <!--
                        <div class="row">
                            <div class="col-12 px-0">
                                <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="login-passwordOld" placeholder="Aktuelles Kennwort" v-model="$v.password.$model">
                            </div>
                        </div>
                        -->
                        <div class="row mt-4">
                            <div class="col-12 px-0">
                                <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="login-passwordNew" placeholder="Neues Kennwort" v-model="$v.passwordNew.$model">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 px-0">
                                <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="login-passwordNew2" placeholder="Neues Kennwort wiederholen" v-model="$v.passwordNewRepeat.$model">
                            </div>
                        </div>
                        <div v-if="errorTokenInvalid" class="text-danger" style="font-weight: bold">
                            Token ungültig! Kennwort konnte nicht geändert werden.
                        </div>
                    </div>
                </div>

                <div v-if="errorTokenInvalid" class="modal-footer border-0 p-0 mt-4">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6 offset-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="isLoading" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="$v.$invalid" @click="doPasswordChange">
                                    Kennwort ändern
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- change password: success -->
            <div class="modal-content p-4" v-if="step === 2">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Kennwort ändern</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0 text-center">
                                Dein Kennwort wurde erfolgreich geändert!
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer border-0 p-0 mt-4">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6 offset-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>



<script>
    import Conf from "../../configs";
    import BaseModal from "./BaseModal";
    import {required, sameAs} from "vuelidate/lib/validators";
    import {apiCall} from "../../helpers";


    const api = Conf.restApi.endpoints.map;


    export default {
        name: "PasswordChange",

        extends: BaseModal,

        props: {
            token: {
                required: true,
                type: String
            }
        },

        data() {
            return {
                modalId: 'password-change-modal',
                modal: undefined,
                isLoading: false,

                toastMsg: '',
                toastTitle: '',
                toastBgColor: undefined,
                showToast: false,

                //password: undefined,
                passwordNew: undefined,
                passwordNewRepeat: undefined,

                step: 1,
                errorTokenInvalid: false
            }
        },

        validations: {
            /*
            password: {
                required
            },
            */
            passwordNew: {
                required
            },
            passwordNewRepeat: {
                sameAsPassword: sameAs('passwordNew')
            },
        },

        methods: {
            async doPasswordChange() {
                this.isLoading = true;
                let payload = {password: this.passwordNew, token: this.token};
                let req = api.user.password.set;
                let res = await apiCall(req.url, req.method, payload)

                if (res.error === 0) {
                    this.step = 2;
                } else {
                    this.errorTokenInvalid = true;
                }
                this.isLoading = false;
            },

        }
    }
</script>



<style scoped>

</style>