<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">

            <!-- success -->
            <div class="modal-content p-4" v-if="verificationSuccess">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Verifizierung erfolgreich!</span>
                    <div class="container px-0 mt-4">

                        <div class="row">
                            <div class="col-12 px-0">
                                <p class="mp-qks mp-font-weight-medium font-size-login-register-modal-verify-email-description">
                                    Verifizierung wurde erfolgreich abgeschlossen!
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row">
                            <div class="col-12">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="switchToLogin">
                                    Weiter zur Anmeldung
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- failure -->
            <div class="modal-content p-4" v-else>
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Verifizierung fehlgeschlagen!</span>
                    <div class="container px-0 mt-4">

                        <div class="row">
                            <div class="col-12 px-0">
                                <p class="mp-qks mp-font-weight-medium font-size-login-register-modal-verify-email-description text-danger text-center">
                                    <b>Token ungültig! Verifizierung konnte nicht abgeschlossen werden.</b>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row">
                            <div class="col-12">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import BaseModal from "./BaseModal";
    import Const from "../../constants";
    import {mixinBasics} from "../../mixins/basics";
    import eventBus from "../../eventbus";
    import {mapActions} from "vuex";


    export default {
        name: "EMailVerify",

        extends: BaseModal,

        mixins: [
            mixinBasics
        ],

        props: {
            token: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                modalId: 'register-user-success',
                modal: undefined,
                verificationSuccess: false,
                verificationFailure: false,
            }
        },

        methods: {
            ...mapActions('main', {
                _registerUserVerify: 'registerUserVerify',
            }),

            async doVerification() {
                let res = await this._registerUserVerify({email: this.email, token: this.token});
                if (res.error === 0) {
                    this.$emit('success');
                    this.verificationSuccess = true;
                } else {
                    let errCode = res.error.toString();
                    this.errorMsg = Const.errors[errCode];
                    this.verificationFailure = true;
                }
            },

            switchToLogin() {
                eventBus.$emit(Const.events.SHOW_LOGIN);
                this.close();
            },
        },

        mounted() {
            this.doVerification();
        }

    }
</script>



<style scoped>

</style>